import React from 'react';
import { Suggest } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core";

export default class MTMTAuthorChooser extends React.Component {

    constructor(props) {
        super(props);  
        this.state = { names: [], selectedInList: null, mtmtIdFromList: "" };
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.query = "";
        this.fetching = false;
    }

    componentDidMount() {        
        if (this.props.author && this.props.author.mtid !== this.state.mtmtIdFromList)
            this.initializeWithAuthor();
    }

    componentDidUpdate(prevProps) {        
        const presentMTMTId = this.props.author ? this.props.author.mtid : "";
        const prevMTMTId = prevProps.author ? prevProps.author.mtid : "";        
        if (presentMTMTId !== prevMTMTId)
            this.initializeWithAuthor();
    }

    initializeWithAuthor() {
        this.setState({ 
            mtmtIdFromList: this.props.author ? this.props.author.mtid : "", 
            names: this.props.author ? [ this.props.author ] : [], 
            selectedInList: this.props.author ? this.props.author : null
        });
    }

    async fetchNames() {
        let fetchingForName = String(this.query);
        try {            
            const data = await window.getMTMTObject("/api/author", `size=200&depth=0&cond=label;any;${this.query}&sort=familyName,asc&sort=givenName,asc&labelLang=hun`);
            this.setState({names: data.content});
            if (this.query !== fetchingForName)
                setTimeout(() => this.fetchNames(), 500);
            else
                this.fetching = false;
        }
        catch (err) {
            console.log(err);
        }
    }

    handleQueryChange(query, evt) {
        if (evt) {
            this.query = query;
            if (query.length > 3 && this.fetching === false) {
                this.fetching = true;
                setTimeout(() => this.fetchNames(), 500);
            }
        }
    }    

    render() {
        return <div className="egyen-kereso-felsosor">
                <div className="egyen-kereso-elem">
                    <span className="egyen-kereso-elem-cimke">{this.props.title}: &nbsp; </span>
                    <Suggest 
                        items={this.state.names}
                        inputValueRenderer={(itm) => itm.label}
                        itemRenderer={(itm,evt) => (
                            <MenuItem
                                text={itm.label}
                                active={evt.modifiers.active}
                                disabled={evt.modifiers.disabled}
                                key={itm.mtid ? itm.mtid : ""}
                                onClick={evt.handleClick}
                            />)}
                        popoverProps={{minimal: true, popoverClassName: "popover"}}
                        onItemSelect={(itm) => {
                            this.setState({mtmtIdFromList: itm.mtid, selectedInList: itm});
                            this.props.onMTMTIdChanged(itm.mtid);
                        }}
                        onQueryChange={this.handleQueryChange}
                        selectedItem={this.state.selectedInList}
                        className={"egyen-kereso-input"}
                    />
                </div>
                <div className="egyen-kereso-elem">
                    <span>MTMT id: &nbsp;</span>
                    <input 
                        type="text" 
                        value={this.props.mtmtid ? this.props.mtmtid : ""} 
                        onChange={(evt) => this.props.onMTMTIdChanged(evt.target.value)} 
                        className="bp3-input egyen-kereso-input" 
                    />
                </div>
            </div>;
    }
}