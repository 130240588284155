import React from 'react';
import { Spinner } from "@blueprintjs/core";
import { useLocation } from "react-router-dom";
import PhDScientometer from './PhDScientometer';
import PhDInputParams from './PhDInputParams';
import withLocation from './withlocation';

class PhDScoringApp extends React.Component {

    constructor(props) {
        super(props);
  
        this.state = { mtmtids: null, pubs: [], student: null, studentid: null, supervisor: null, supervisorid: null, loading: false, date: false };
        this.handleAuthorSelected = this.handleAuthorSelected.bind(this);
    }

    componentDidMount() {        
        if (this.props.params.mtmtids && this.props.params.mtmtids ) {
            const ids = this.props.params.mtmtids.split("+");
            this.loadPublications(ids[0], ids[1], false);
        }
    }

    componentDidUpdate(prevProps) {        
        if (this.props.params.mtmtids && this.props.params.mtmtids !== prevProps.params.mtmtids) {
            const ids = this.props.params.mtmtids.split("+");
            this.loadPublications(ids[0], ids[1], false);
        }
    }

    handleAuthorSelected(stmtmtid, svmtmtid, date) {
        this.loadPublications(stmtmtid, svmtmtid ? svmtmtid : "", date);
        this.props.navigate("/phdscoring/"+stmtmtid+"+"+(svmtmtid ? svmtmtid : ""));
    }

    async loadPublications(stmtmtid, svmtmtid, date) {
        this.setState({loading: true});
        const selectedid = String(stmtmtid);
        try {
            const pubData = await window.getMTMTObject("/api/publication", `cond=published;eq;true&cond=core;eq;true&cond=authors.mtid;eq;${selectedid}&sort=publishedYear,desc&sort=firstAuthor,asc&size=2000&fields=citations&labelLang=hun&page=1`);
            const authorData = await window.getMTMTObject(`/api/author/${selectedid}`);
            const supervisorData = await window.getMTMTObject(`/api/author/${String(svmtmtid)}`);
            this.setState({
                pubs: pubData.content, 
                student: authorData.content,
                supervisor: supervisorData.content,
                studentid: selectedid, 
                supervisorid: String(svmtmtid), 
                loading: false, 
                date: date
            });
        } catch (err) {
            console.log(err);
            this.setState({
                pubs: [], 
                student: null,
                supervisor: null,
                studentid: selectedid, 
                supervisorid: String(svmtmtid), 
                loading: false, 
                date: false
            });
        }
    }

    render() {
        return <div>
            <PhDInputParams onAuthorSelected={this.handleAuthorSelected} student={this.state.student} supervisor={this.state.supervisor} />

            {!this.state.loading && this.state.studentid !== null && 
                <PhDScientometer 
                    studentid={this.state.studentid} 
                    supervisorid={this.state.supervisorid} 
                    pubs={this.state.pubs} 
                    date={this.state.date} 
                    student={this.state.student} 
                    supervisor={this.state.supervisor} 
                />
            }
            {this.state.loading && <div className="mtmtspinner">
                <Spinner intent="primary" size="100" />
                <p>Publikációs adatok letöltése az MTMT-ből...</p>
                </div>
            }
        </div>
    }
}

export default withLocation(PhDScoringApp);