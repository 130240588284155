import React from 'react';
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import MTMTAuthorChooser from "./MTMTAuthorChooser";
import { Button, Card, Checkbox, Intent, Elevation } from "@blueprintjs/core";

export default class PhDInputParams extends React.Component {

    constructor(props) {
        super(props);  
        this.state = { studentMtmtId: "", supervisorMtmtId: "", date: null, dateEnabled: false };
    }

    componentDidMount() {        
        if ((this.props.student && this.props.student.mtid !== this.state.studentMtmtId) || (this.props.supervisor && this.props.supervisor.mtid !== this.state.supervisorMtmtId))
            this.initializeIds();
    }

    componentDidUpdate(prevProps) {        
        const presentStMTMTId = this.props.student ? this.props.student.mtid: "";
        const prevStMTMTId = prevProps.student ? prevProps.student.mtid: "";        
        const presentSvMTMTId = this.props.supervisor ? this.props.supervisor.mtid: "";
        const prevSvMTMTId = prevProps.supervisor ? prevProps.supervisor.mtid: "";        
        if (presentStMTMTId !== prevStMTMTId || presentSvMTMTId !== prevSvMTMTId)
            this.initializeWithAuthors();
    }

    initializeWithAuthors() {
        this.setState({ 
            studentMtmtId: this.props.student ? this.props.student.mtid : "", 
            supervisorMtmtId: this.props.supervisor ? this.props.supervisor.mtid : "", 
        });
    }

    render() {
        return <Card className="egyen-kereso" elevation={Elevation.TWO}>
            <MTMTAuthorChooser 
                title="Doktorandusz neve:"
                author={this.props.student} 
                mtmtid={this.state.studentMtmtId} 
                onMTMTIdChanged={mtid => this.setState({studentMtmtId: mtid})}
            />
            <MTMTAuthorChooser 
                title="Témavezető neve:"
                author={this.props.supervisor} 
                mtmtid={this.state.supervisorMtmtId} 
                onMTMTIdChanged={mtid => this.setState({supervisorMtmtId: mtid})}
            />          
            <div className="egyen-kereso-datum">
                <Checkbox 
                    checked={this.state.dateEnabled} 
                    label="Záró dátum megadása, előterjesztőknek" 
                    onChange={() => this.setState({dateEnabled: !this.state.dateEnabled})} 
                />
            </div>
            {this.state.dateEnabled &&
                <div className="egyen-kereso-elem egyen-kereso-behuzott">
                    <span>Eddig a dátumig: &nbsp;</span>
                    <DateInput 
                        value={this.state.date} 
                        onChange={(date) => this.setState({ date })}
                        formatDate={date => moment(date).format("YYYY/MM/DD")}
                        parseDate={str => moment(str, "YYYY/MM/DD").toDate()}
                        placeholder={"ÉV/HÓ/NAP"}
                        className={"egyen-kereso-input"}
                    />
                </div>
            }
            <div>
                <Button 
                    text="Pontozás" 
                    intent={Intent.PRIMARY} 
                    onClick={() => this.props.onAuthorSelected(this.state.studentMtmtId, this.state.supervisorMtmtId, this.state.dateEnabled ? this.state.date : false)} 
                    className="scoring-button"
                />
            </div>
        </Card>;
    }
}