import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import { ProgressBar, Intent, NonIdealState } from "@blueprintjs/core";
import _ from 'lodash';
import moment from "moment";
import { CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, Filler, Tooltip, LineController } from 'chart.js'; 
Chart.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, Filler, Tooltip, LineController);

export default class MTAMTOScientometer extends React.Component {

    constructor(props) {
        super(props);
   
        this.defaultState = {
            // 1. pubSzam
            // 2.
            angolLektoraltFI: { count: 0, ids: [] },
            // 3.
            wosScopusLektoraltFI: { count: 0, ids: [] },
            // 5.
            angolMin50: { count: 0, ids: [] },
            // 6. pontSzam
            categ: {},
            // --
            scoreYears: {},
            pubList: [],
            nextToProcess: 0
        };
        this.categories = {
            "Idegen nyelvű könyv": 8,
            "Idegen nyelvű könyvrész, könyvfejezet": 6,
            "Magyar nyelvű könyv": 5,
            "Magyar nyelvű könyvrész, könyvfejezet": 3,
            "Szerkesztett kötetben cikk": 4,
            "Nyomtatott (vagy elektronikus formában közzétett) egyetemi jegyzet": 3,
            "Külföldön megjelent idegen nyelvű lektorált folyóiratcikk": 6,
            "Magyarországon megjelent idegen nyelvű lektorált folyóiratcikk": 4,
            "Magyar nyelvű lektorált folyóiratcikk": 2,
            "Külföldön megjelent idegen nyelvű nem lektorált folyóiratcikk": 3,
            "Magyarországon megjelent idegen nyelvű nem lektorált folyóiratcikk": 2,
            "Magyar nyelvű nem lektorált folyóiratcikk": 1,
            "Nemzetközi részvételű konferencia kiadványában megjelent idegen nyelvű előadás": 3,
            "Helyi részvételű rendezvény kiadványában megjelent idegen nyelvű előadás": 2,
            "Magyar nyelvű, kiadványban megjelent konferencia-előadás": 1,
            "Külföldi szabadalom": 2,
            "Magyar szabadalom": 2,
            "Recenzió (más szerző művének kinyomtatott értékelése)": 1,
            "Elektronikus publikáció": 1
        }
        Object.keys(this.categories).forEach(c => this.defaultState.categ[c] = { count: 0, ids: [], share: 0 })

        this.state = _.cloneDeep(this.defaultState);
        this.score_next_pub = this.score_next_pub.bind(this);

        window.matchMedia("print").onchange = function() { 
            for (var id in Chart.instances) {
                Chart.instances[id].resize();
            }
        };
    }

    componentDidMount() {
        this.process();
    }

    componentDidUpdate(prevProps) {
        if (this.props.studentid !== prevProps.studentid || this.props.supervisorid !== prevProps.supervisorid)
            this.process();
    }

    async process() {
        this.setState(this.defaultState, () => { if (this.props.pubs.length > 0) setTimeout(this.score_next_pub, 0); });
    }

    async internationalCollaboration(pub) {
        if (pub.collaboration) {
            return pub.collaboration === "INTERNATIONAL";
        }
        else if (pub.book) {
            const bookDta = await window.getMTMTObject(pub.book.link);
            if (!bookDta.content)
                return false;            
            return this.internationalCollaboration(bookDta.content);
        }
        else if (pub.conference) {
            const confDta = await window.getMTMTObject(pub.conference.link);
            if (!confDta.content)
                return false;            
            return this.internationalCollaboration(confDta.content);
        }
        return false;
    }

    increment(obj, pub) {
        obj.count += 1;
        obj.ids.push(pub.mtid);
    }

    async score_next_pub() {

        const score = _.cloneDeep(this.state);

        const pub = this.props.pubs[this.state.nextToProcess];
        const date = new Date(pub.created);

        const thispub = {
            title: pub.title, 
            type: "",
            category: "",
            score: 0,
            share: 0,
            comment: "",
            link: pub.link,
        };

        const finish_pub_scoring = (includeCurrent=true) => {
            score.nextToProcess += 1;
            if (includeCurrent)
                score.pubList.push(thispub);
            this.setState(score, () => { 
                if (score.nextToProcess < this.props.pubs.length)
                    setTimeout(this.score_next_pub, 0);
            });
        }

        if (pub.error && pub.error!=="VALIDATION_ERROR") {
            thispub.comment += `(Hiba: ${pub.error})`;
            finish_pub_scoring();
            return;
        }

        thispub.type += `${pub.publishedYear}, ${pub.authorships.length} szerző, ${pub.type.label}`;
        if (pub.subType)
            thispub.type += "/" + pub.subType.label;

        if ((this.props.date && date>this.props.date) || !pub.category) {            
            if (!pub.category) 
                thispub.comment += "(Nincs kategória! Itt valami nem stimmel.)";

            finish_pub_scoring(!this.props.date || date<=this.props.date);
            return;
        }
        let author = false;
        let sumShare = 0;
        let share = 0;
        for (const a of pub.authorships) {
            if (a.author && String(a.author.mtid)===this.props.studentid && a.authorTyped===true) {
                author = true;
                if (a.share && a.share>0)
                    share = a.share;
                else
                    share = 1;
                sumShare += share;
            }
            else if (((a.author && String(a.author.mtid)!==this.props.supervisorid) || !a.author) && a.authorTyped===true) {
                if (a.share && a.share>0)
                    sumShare += a.share;
                else
                    sumShare += 1;
            }
        }
        share /= sumShare;
        thispub.share = share;

        if (!author) {
            thispub.comment += "(Nem szerző!!!)";
            finish_pub_scoring();
            return;
        }

        if (pub.category.label==="Tudományos") {
            // Analyze journal articles
            if (pub.otype === "JournalArticle" && pub.journal) {
                if (pub.fullPublication && pub.subType && 
                        (  pub.subType.name==="Szakcikk" 
                        || pub.subType.name==="Összefoglaló cikk" 
                        || pub.subType.name==="Konferenciaközlemény"
                        || pub.subType.name==="Rövid közlemény"
                        || pub.subType.name==="Sokszerzős vagy csoportos szerzőségű szakcikk"
                        )) {
                    if (pub.journal.reviewType==="REVIEWED") {
                        if (pub.foreignLanguage) {
                            this.increment(score.angolLektoraltFI, pub);                       
                            if (pub.identifiers)
                                for (const i of pub.identifiers)
                                    if (i.source.label === "WoS" || i.source.label === "Wos-CSCD (Chinese)" || i.source.label === "Scopus") {
                                        this.increment(score.wosScopusLektoraltFI, pub);                       
                                        break;
                                    }
                        }
                        if (pub.foreignEdition)
                            thispub.category = "Külföldön megjelent idegen nyelvű lektorált folyóiratcikk";
                        else if (!pub.foreignEdition && pub.foreignLanguage)
                            thispub.category = "Magyarországon megjelent idegen nyelvű lektorált folyóiratcikk";
                        else if (!pub.foreignEdition && !pub.foreignLanguage)
                            thispub.category = "Magyar nyelvű lektorált folyóiratcikk";
                    }
                    else {
                        if (pub.foreignEdition)
                            thispub.category = "Külföldön megjelent idegen nyelvű nem lektorált folyóiratcikk";
                        else if (!pub.foreignEdition && pub.foreignLanguage)
                            thispub.category = "Magyarországon megjelent idegen nyelvű nem lektorált folyóiratcikk";
                        else if (!pub.foreignEdition && !pub.foreignLanguage)
                            thispub.category = "Magyar nyelvű nem lektorált folyóiratcikk";
                    }
                }
            }
            else if (pub.fullPublication && 
                    (( pub.type.label==="Könyvrészlet" && pub.subType &&  pub.subType.label==="Konferenciaközlemény (Könyvrészlet)")
                    || pub.type.label==="Egyéb konferenciaközlemény"
                    )) {
                if (pub.foreignEdition)
                    thispub.category = "Nemzetközi részvételű konferencia kiadványában megjelent idegen nyelvű előadás";
                else if (!pub.foreignEdition && pub.foreignLanguage)
                    thispub.category = "Helyi részvételű rendezvény kiadványában megjelent idegen nyelvű előadás";
                else if (!pub.foreignEdition && !pub.foreignLanguage)
                    thispub.category = "Magyar nyelvű, kiadványban megjelent konferencia-előadás";
            }
            else if (pub.type.label==="Könyv") {
                if (pub.foreignLanguage)
                    thispub.category = "Idegen nyelvű könyv";
                else
                    thispub.category = "Magyar nyelvű könyv";
            }
            else if (pub.type.label==="Könyvrészlet" && pub.subType && (pub.subType.label==="Könyvfejezet (Könyvrészlet)" || pub.subType.label==="Szaktanulmány (Könyvrészlet)")) {
                if (pub.foreignLanguage)
                    thispub.category = "Idegen nyelvű könyvrész, könyvfejezet";
                else
                    thispub.category = "Magyar nyelvű könyvrész, könyvfejezet";
            }
            else if (pub.type.label==="Oltalmi formák" && pub.subType 
                && pub.subType!=="Mintaoltalom (Oltalmi formák)" 
                && pub.subType!=="Minősített növényfajták és állatfajták (Oltalmi formák)" 
                && pub.subType!=="Nem besorolt  szabadalom (Oltalmi formák)") {

                if (pub.subType!=="Magyar szabadalom (Oltalmi formák)")
                    thispub.category = "Külföldi szabadalom";
                else
                    thispub.category = "Magyar szabadalom";
            }
            if (thispub.category !== "" && pub.foreignLanguage && share>=0.5)
                this.increment(score.angolMin50, pub);

            if (thispub.category === "")
                thispub.category = "Ismeretlen kategóriájú tudományos mű";
        }
        if ((pub.type.label==="Könyv" && pub.subType && (pub.subType.label==="Felsőoktatási tankönyv (Könyv)" || pub.subType.label==="Oktatási anyag (Könyv)"))
            || (pub.type.label==="Könyvrészlet" && pub.subType && (pub.subType.label==="Felsőoktatási tankönyv része (Könyvrészlet)" || pub.subType.label==="Oktatási anyag része (Könyvrészlet)"))) {

            let plength = 0;
            if (pub.pageLength)
                plength = pub.pageLength;
            else if (pub.firstPage && pub.lastPage)
                plength = pub.lastPage - pub.firstPage + 1;
    
            if (plength >= 25)
                thispub.category = "Nyomtatott (vagy elektronikus formában közzétett) egyetemi jegyzet";
            else
                thispub.category = `Nyomtatott (vagy elektronikus formában közzétett) egyetemi jegyzet, túl rövid, ${plength} oldal`;
        }
        else if ((pub.type.label==="Könyvrészlet" && pub.subType && pub.subType.label==="Recenzió/kritika (Könyvrészlet)")
            || (pub.type.label==="Folyóiratcikk" && pub.subType && pub.subType.label==="Recenzió/kritika (Folyóiratcikk)")
            || (pub.type.label==="Könyv" && pub.subType && pub.subType.label==="Kritikai kiadás (Könyv)")) {

            thispub.category = "Recenzió (más szerző művének kinyomtatott értékelése)";
        }
        else if (pub.type.label==="Egyéb" && pub.subType && (pub.subType.label==="Kutatási jelentés (közzétett) (Egyéb)" || pub.subType.label==="Csak repozitóriumban hozzáférhető közlemény (Egyéb)")) {
            thispub.category = "Elektronikus publikáció";
        }

        if (thispub.category==="")
            thispub.category = "Nem pontozott publikáció";

        if (score.categ[thispub.category]) {
            score.categ[thispub.category].count += 1;
            score.categ[thispub.category].share += share;
            score.categ[thispub.category].ids.push(pub.mtid);
            thispub.score = share * this.categories[thispub.category];
            if (score.scoreYears[pub.publishedYear])
                score.scoreYears[pub.publishedYear] += share * this.categories[thispub.category];
            else
                score.scoreYears[pub.publishedYear] = share * this.categories[thispub.category];
        }

        finish_pub_scoring();
    }

    getMTMTPubLink(counter) {
        if (counter.count > 0)
            return <a href={"https://m2.mtmt.hu/api/publication?cite_type=2&size=5000&cond=mtid;in;"+counter.ids.join()}>{counter.count}</a>;
        else
            return 0;
    }

    getRow(catLabel) {
        return <tr><td className="row-label">{catLabel}</td>
        <td>{this.getMTMTPubLink(this.state.categ[catLabel])}</td>
        <td>{(this.state.categ[catLabel].share * this.categories[catLabel]).toFixed(2)}</td>
        </tr>
    }

    getSuccess(cond) {
        if (cond) 
            return <span className="passed">✔</span>;
        else
            return <span className="failed">✗</span>;
    }

    render() {

        if (this.props.pubs.length === 0)
            return <NonIdealState 
                title = "Nem pontozható!"
                description = "A megadott azonosítójú felhasználó nem létezik, vagy üres a publikációs listája."
                icon = "warning-sign"
            />

        const progress = this.state.nextToProcess / this.props.pubs.length;

        let fullScore = 0;
        let fullCount = 0;
        for (const c in this.state.categ) {
            fullCount += this.state.categ[c].count;
            fullScore += this.state.categ[c].share * this.categories[c];
        }

        const scoredPubs = this.state.pubList.filter(pub => pub.score > 0);
        const phdProgress = (scoredPubs.length >= 4 ? 0.2 : 0) 
                        +(this.state.angolLektoraltFI.count >= 2 ? 0.2 : 0)  
                        +(this.state.wosScopusLektoraltFI.count >= 1 ? 0.2 : 0)  
                        +(this.state.angolMin50.count >= 2 ? 0.2 : 0)
                        +Math.min(fullScore/12, 1.0)*0.2;

        const plotYears = [];
        const scorePlot = [];
        if (this.state.nextToProcess === this.props.pubs.length) {
            const firstYear = Math.min(...Object.keys(this.state.scoreYears));
            const lastYear = Math.max(...Object.keys(this.state.scoreYears));
            for (let i=firstYear; i<=lastYear; i++) {
                plotYears.push(i);
                scorePlot.push(this.state.scoreYears[i] ? this.state.scoreYears[i] : 0);
            }
            for (let i=1; i<scorePlot.length; i++)
                scorePlot[i] += scorePlot[i-1];            
        }

        return <div>
          <div className="egyen-main-progress">
              <ProgressBar value={progress} animate={false} intent={progress<1 ? Intent.PRIMARY : Intent.SUCCESS} />
          </div>

          <div className="egyen-titulusok">
              <h1> <a href={"https://m2.mtmt.hu/api/author/"+this.props.studentid}>{this.props.student.label}</a></h1>
              {(this.props.supervisor && this.props.supervisorid!=="") && <h2> Témavezető: <a href={"https://m2.mtmt.hu/api/author/"+this.props.supervisorid}>{this.props.supervisor.label}</a> </h2>}
              <h3> Lekérdezés dátuma: {(new Date()).toLocaleString("hu")} </h3>
              {this.props.date && <h3>Záródátum: {moment(this.props.date).format("YYYY. MM. DD.")}</h3>}
          </div>

          <div className="egyen-main-panel">
            <div className="egyen-tablazat-panel-phd">
            <h3>Összefoglaló táblázat</h3>
            <table className="egyen-tablazat">
                <thead>
                <tr><th></th><th rowSpan="2">Pontszám</th><th>Követelmény</th><th>Teljesül</th></tr>
                </thead>
                <tbody>
                <tr>
                    <td>Publikációk száma összesen:</td><td>{scoredPubs.length}</td><td>4</td><td>{this.getSuccess(scoredPubs.length >= 4)}</td>
                </tr>
                <tr>
                    <td>Angol nyelvű lektorált folyóiratcikkek száma:</td><td>{this.getMTMTPubLink(this.state.angolLektoraltFI)}</td><td>2</td><td>{this.getSuccess(this.state.angolLektoraltFI.count >= 2)}</td>
                </tr>
                <tr>
                    <td>WoS vagy Scopus adatbázisban szereplő folyóiratcikkek száma:</td><td>{this.getMTMTPubLink(this.state.wosScopusLektoraltFI)}</td><td>1</td><td>{this.getSuccess(this.state.wosScopusLektoraltFI.count >= 1)}</td>
                </tr>
                <tr>
                    <td>Angol nyelvű, a jelölt legalább 50%-os részével publikált cikkek száma:</td><td>{this.getMTMTPubLink(this.state.angolMin50)}</td><td>2</td><td>{this.getSuccess(this.state.angolMin50.count >= 2)}</td>
                </tr>
                <tr>
                    <td>Publikációs pontszám:</td><td>{fullScore.toFixed(2)}</td><td>12</td><td>{<span className={fullScore>=12 ? "passed": "failed"}>{(fullScore*100/12).toFixed(1)}%</span>}</td>
                </tr>
                <tr className="egyen-summary">
                    <td>Előrehaladás:</td><td colSpan="3">{(phdProgress*100).toFixed(1)}%<ProgressBar animate={false} value={phdProgress} intent={phdProgress<0.5 ? Intent.DANGER : phdProgress<1.0 ? Intent.WARNING : Intent.SUCCESS}/></td>
                </tr>
                </tbody>
            </table>
            </div>

            <div className="egyen-plot-panel-single">
            <div className="egyen-plot">
                    <Bar options={{ responsive: true, maintainAspectRatio: false }} data={{
                        labels: plotYears,
                        datasets: [
                            {
                                label: "Publikációs pontszám",
                                backgroundColor: '#2196f3',
                                data: scorePlot
                            }
                        ]
                    }}/>
                </div>
            </div>

            </div>        
            <div className="eloterjesztoi-panel">
            <h3>A doktorandusz publikációs teljesítménye</h3>
            <table className="eloterjesztoi-tabla">
                <thead>
                    <tr><th>Kategória</th><th>Darab</th><th>Pont</th></tr>
                </thead>
                <tbody>
                <tr><th>Könyv</th><th></th><th></th></tr>
                {this.getRow("Idegen nyelvű könyv")}
                {this.getRow("Idegen nyelvű könyvrész, könyvfejezet")}
                {this.getRow("Magyar nyelvű könyv")}
                {this.getRow("Magyar nyelvű könyvrész, könyvfejezet")}
                {this.getRow("Szerkesztett kötetben cikk")}
                {this.getRow("Nyomtatott (vagy elektronikus formában közzétett) egyetemi jegyzet")}
                <tr><th>Folyóiratcikk</th><th></th><th></th></tr>
                {this.getRow("Külföldön megjelent idegen nyelvű lektorált folyóiratcikk")}
                {this.getRow("Magyarországon megjelent idegen nyelvű lektorált folyóiratcikk")}
                {this.getRow("Magyar nyelvű lektorált folyóiratcikk")}
                {this.getRow("Külföldön megjelent idegen nyelvű nem lektorált folyóiratcikk")}
                {this.getRow("Magyarországon megjelent idegen nyelvű nem lektorált folyóiratcikk")}
                {this.getRow("Magyar nyelvű nem lektorált folyóiratcikk")}
                <tr><th>Konferenciacikk</th><th></th><th></th></tr>
                {this.getRow("Nemzetközi részvételű konferencia kiadványában megjelent idegen nyelvű előadás")}
                {this.getRow("Helyi részvételű rendezvény kiadványában megjelent idegen nyelvű előadás")}
                {this.getRow("Magyar nyelvű, kiadványban megjelent konferencia-előadás")}
                <tr><th>Szabadalom</th><th></th><th></th></tr>
                {this.getRow("Külföldi szabadalom")}
                {this.getRow("Magyar szabadalom")}
                <tr><th>Egyéb</th><th></th><th></th></tr>
                {this.getRow("Recenzió (más szerző művének kinyomtatott értékelése)")}
                {this.getRow("Elektronikus publikáció")}
                <tr className="main-row bold-text"><td>Összesen:</td><td>{fullCount}</td><td>{fullScore.toFixed(2)}</td></tr>
                </tbody>
            </table>
            </div>
            {<div>
            <h3>Pontozás részletező</h3>
            <table className="pontozo-tabla">
                <thead>
                <tr><th>Publikáció</th><th>Részarány</th><th>Pontszám</th></tr>
                </thead>
                <tbody>
                {this.state.pubList.map((value, index) => {
                    return <tr key={index}><td className="row-label"><a href={"https://m2.mtmt.hu"+value.link}>{value.title}</a><br/><i>{value.category}</i><br/>{value.type}  {value.comment}</td><td>{value.share.toFixed(2)}</td><td>{value.score.toFixed(2)}</td></tr>
                })}
                </tbody>
            </table>
            </div>}
        </div>
    }
  }