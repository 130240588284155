import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import * as d3 from 'd3-fetch';
import { ProgressBar, Intent, NonIdealState, Spinner } from "@blueprintjs/core";
import _ from 'lodash';
import moment from "moment";
import { CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, Filler, Tooltip, LineController } from 'chart.js'; 
Chart.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, Filler, Tooltip, LineController);

export default class MTAMTOScientometer extends React.Component {

    constructor(props) {
        super(props);
   
        this.defaultState = {
            iftabloading: false,
            lekfoly: { kulfoldon: {count: 0, ids: []}, moinyelv: {count: 0, ids: []}, momagyar: {count: 0, ids: []}},
            q1lekfoly: { kulfoldon: {count: 0, ids: []}, moinyelv: {count: 0, ids: []}, momagyar: {count: 0, ids: []}},
            iflekfoly: { kulfoldon: {count: 0, ids: []}, moinyelv: {count: 0, ids: []}, momagyar: {count: 0, ids: []}},
            egysziflekfoly: { kulfoldon: {count: 0, ids: []}, moinyelv: {count: 0, ids: []}, momagyar: {count: 0, ids: []}},
            konfcikk: { kulfoldon: {count: 0, ids: []}, moinyelv: {count: 0, ids: []}, momagyar: {count: 0, ids: []}},
            konyv: { kulfoldon: {count: 0, ids: []}, moinyelv: {count: 0, ids: []}, momagyar: {count: 0, ids: []}},
            konyvresz: { kulfoldon: {count: 0, ids: []}, moinyelv: {count: 0, ids: []}, momagyar: {count: 0, ids: []}},
            adatbazisban: { kulfoldon: {count: 0, ids: []}, moinyelv: {count: 0, ids: []}, momagyar: {count: 0, ids: []}},
            weben: { kulfoldon: {count: 0, ids: []}, moinyelv: {count: 0, ids: []}, momagyar: {count: 0, ids: []}},            
            osszespub: 0,
            folypubscr: 0,
            konfcikkpubscr: 0,
            konyvpubscr: 0,
            konyvreszpubscr: 0,
            if: 0,
            relif: 0,
            ifnorm: 0,
            relifnorm: 0,
            idezetek: Array(5).fill(0).map(() => Array(6).fill(0).map(() => ({count: 0, ids: []}))),
            wosidezet: {count: 0, ids: []},
            scopusidezet: {count: 0, ids: []},
            scholaridezet: {count: 0, ids: []},
            iscore: 0,
            osszesidezo: 0,
            qpaper: 0,
            qpapernorm: 0,
            qbook: 0,
            publist: [],
            idezetidosor: [],
            hindex: 0,
            qBookPlot: {},
            qPaperPlot: {},
            rD1Plot: {},
            rQ1Plot: {},
            rQ2Plot: {},
            rQ3Plot: {},
            rQ4Plot: {},
            iPlot: {},
            iWosPlot: {},
            pubsPlot: {},
            nextToProcess: 0,
            sort: "year"
        };
        this.state = _.cloneDeep(this.defaultState);
        this.score_next_pub = this.score_next_pub.bind(this);

        window.matchMedia("print").onchange = function() { 
            for (var id in Chart.instances) {
                Chart.instances[id].resize();
            }
        };
    }

    componentDidMount() {
        this.process();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedid !== prevProps.selectedid)
            this.process();
    }

    async process() {
        if (!window.ifdf) {
            this.setState({ iftabloading: true });
            window.ifdf = await d3.csv(process.env.PUBLIC_URL+"/ifdf_v10_2023.csv");
            window.maxYear = 0;
            for (let i of window.ifdf)
                if (parseInt(i.year) > window.maxYear)
                    window.maxYear = parseInt(i.year);
        }
        if (this.props.options.norway && !window.norway)
            window.norway = await d3.csv(process.env.PUBLIC_URL+"/norway_v1_2024.csv");
        this.setState(this.defaultState, () => { if (this.props.pubs.length > 0) setTimeout(this.score_next_pub, 0); });
    }

    impact_result(ifct) {
        const ifv = parseFloat(ifct.if), normv = parseFloat(ifct.categoryMedianIf);
        if (normv && normv > 0.0)
            return [ifv, ifv/normv, ifct.quartile];
        else
            return [ifv, 0.0, ifct.quartile];
    }

    get_norway(pub) {
        if (!pub.journal || !window.norway || !this.props.options.norway)
            return "";

        const year = Math.min(pub.publishedYear, window.maxYear);

        // try to find level based on ISSN
        for (const nrw of window.norway)
            if ((pub.journal.pIssn && nrw["Print ISSN"] === pub.journal.pIssn) || (pub.journal.eIssn && nrw["Online ISSN"] === pub.journal.eIssn)) {
                const column = `Level ${pub.publishedYear}`;
                if (column in nrw) return nrw[column];
            }
        return "";
    }

    async get_impact(pub) {

        if (!pub.journal/* || pub.journal.noIF*/)
            return [0.0, 0.0, "?"];

        const year = Math.min(pub.publishedYear, window.maxYear);

        // try to find IF based on ISSN
        for (const ifct of window.ifdf)
            if (parseInt(ifct.year) === year && (ifct.eissn === pub.journal.eIssn || ifct.pissn === pub.journal.pIssn || ifct.eissn === pub.journal.pIssn || ifct.pissn === pub.journal.eIssn))
                return this.impact_result(ifct);

        //console.log("IF not found based on the ISSN: ", pub.journal.label, year, pub.journal);
                
        // try to find IF based on journal name abbreviation
        const jname = pub.journal.abbreviated;
        if (jname && jname.length>0)
            for (const ifct of window.ifdf)
                if (parseInt(ifct.year) === year && ifct.journal.toUpperCase() === jname.toUpperCase())
                    return this.impact_result(ifct);

        const altNames = pub.journal.altNames;
        if (altNames && altNames.length>0)
            for (const altName of altNames.split("\n"))
                for (const ifct of window.ifdf)
                    if (parseInt(ifct.year) === year && ifct.journal.toUpperCase() === altName.toUpperCase())
                        return this.impact_result(ifct);

        //console.log("IF not found based on abbreviation: ", pub.journal.label, year, pub.journal);
        
        return [0.0, 0.0, "?"];
    }

    check_identifiers(object) {

        if (object.identifiers && object.identifiers.length>0)
            for (const id of object.identifiers)
                if (id.source && (id.source.name==="ISBN" || id.source.name==="ISSN"))
                    return true;

        return false;
    }


    async has_isbn_issn(pub) {

        if (this.check_identifiers(pub))
            return true;
        
        if (pub.book) {
            if (this.check_identifiers(pub.book))
                return true;

            try {                    
                const bookDta = await window.getMTMTObject(pub.book.link);
                if (!bookDta.content)
                    return false;            
                    
                if (this.check_identifiers(bookDta.content))
                    return true;

                if (bookDta.content.conference) {

                    try {
                        const confDta = await window.getMTMTObject(bookDta.content.conference.link);
                        if (confDta && confDta.content && this.check_identifiers(confDta.content))
                            return true;
                    } catch (err) {
                        console.log(err);
                    }
                }

                if (bookDta.content.seriesMembers && bookDta.content.seriesMembers.length>0) {

                    for (const series of bookDta.content.seriesMembers) {
                        try {
                            const seriesDta = await window.getMTMTObject(series.link);
                            if (seriesDta && seriesDta.content && (
                                (seriesDta.content.issns && seriesDta.content.issns.length>0)
                                || seriesDta.content.pIssn || seriesDta.content.eIssn || !seriesDta.content.noISSN
                                ))
                                return true;
                        } catch (err) {
                            console.log(err);
                        }
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
        return false;
    }

    async get_citation_date(c) {

        if (!c.link)
            return null;
        try {
            const citdta = await window.getMTMTObject(c.link);
            return new Date(citdta.content.created);
        } catch (err) {
            console.log(err);
            return null;
        }
    }    

    classify_pub(pub, scr) {
        if (pub.foreignEdition) {
            scr.kulfoldon.count += 1;
            scr.kulfoldon.ids.push(pub.mtid);
        }
        else if (!pub.foreignEdition && pub.foreignLanguage) {
            scr.moinyelv.count += 1;
            scr.moinyelv.ids.push(pub.mtid);
        }
        else if (!pub.foreignEdition && !pub.foreignLanguage) {
            scr.momagyar.count += 1;
            scr.momagyar.ids.push(pub.mtid);
        }
    }

    array_add(array, index, value) {
        if (Object.keys(array).includes(String(index))) 
            array[index] += value;
        else
            array[index] = value;
    }

    async score_next_pub() {

        const score = _.cloneDeep(this.state);

        const pub = this.props.pubs[this.state.nextToProcess];
        const [ifscr, nifscr, rank] = await this.get_impact(pub);
        const date = new Date(pub.created);

        const thispub = {
            title: pub.title, 
            type: "",
            qscore: 0,
            qnscore: 0,
            iscore: 0,
            comment: "",
            link: pub.link,
            allcit: 0,
        };

        const finish_pub_scoring = (includeCurrent=true) => {
            score.nextToProcess += 1;
            if (includeCurrent)
                score.publist.push(thispub);
            this.setState(score, () => { 
                if (score.nextToProcess < this.props.pubs.length)
                    setTimeout(this.score_next_pub, 0);
            });
        }

        if (pub.error && pub.error!=="VALIDATION_ERROR") {
            thispub.comment += `(Hiba: ${pub.error})`;
            finish_pub_scoring();
            return;
        }

        thispub.type += `${pub.publishedYear}, ${pub.authorships.length} szerző, ${pub.type.label}`;
        if (pub.subType)
            thispub.type += "/" + pub.subType.label;

        if ((this.props.date && date>this.props.date) || !pub.category || pub.category.label!=="Tudományos") {            
            if (!this.props.date || date<=this.props.date)
                thispub.comment += "(Nem tudományos!!!)";
            if (!pub.category) 
                thispub.comment += "(Nincs kategória! Itt valami nem stimmel.)";

            finish_pub_scoring(!this.props.date || date<=this.props.date);
            return;
        }
        let author = false;
        let share = pub.authorships.length>0 ? 1.0/pub.authorships.length : 0;
        for (const a of pub.authorships)
            if (a.author && String(a.author.mtid)===this.props.selectedid && a.authorTyped===true) {
                author = true;
                if (a.share && a.share>0 && this.props.options.share)
                    share = a.share;
                break;
            }
        if (!author) {
            thispub.comment += "(Nem szerző!!!)";
            finish_pub_scoring();
            return;
        }

        let qpaper = 0;
        let qndiff = 0;
        let qbook = 0;
        let minmeret = 0;

        let plength = 0;
        if (pub.pageLength)
            plength = pub.pageLength;
        else if (pub.firstPage && pub.lastPage)
            plength = pub.lastPage - pub.firstPage + 1;

        let scijourconf = false;
        let isbnmissing = false;
        // Analyze journal articles
        if (pub.otype === "JournalArticle" && pub.journal) {
            if (pub.fullPublication && pub.journal.reviewType==="REVIEWED" && pub.subType && 
                    (  pub.subType.name==="Szakcikk" 
                    || pub.subType.name==="Összefoglaló cikk" 
                    || pub.subType.name==="Konferenciaközlemény"
                    || pub.subType.name==="Rövid közlemény"
                    || pub.subType.name==="Sokszerzős vagy csoportos szerzőségű szakcikk"
                    )) {
                this.classify_pub(pub, score.lekfoly);

                // q score, if és relif számolás
                let totalscr;
                if (ifscr>0) {
                    totalscr = Math.max(0.6,ifscr);
                    score.if += ifscr;
                    score.relif += totalscr * share;
                    this.classify_pub(pub, score.iflekfoly);
                    if (pub.authorships.length===1)
                        this.classify_pub(pub, score.egysziflekfoly);
                }
                else if (pub.foreignEdition)
                    totalscr = 0.4;
                else
                    totalscr = 0.3;
                qpaper = totalscr * share;
                score.folypubscr += qpaper;

                // normalizált q score, if és relif számolás
                if (nifscr>0) {
                    totalscr = Math.max(0.6,nifscr);
                    score.ifnorm += nifscr;
                    score.relifnorm += totalscr * share;
                }
                else if (ifscr>0)
                    totalscr = 0.6;
                else if (pub.foreignEdition)
                    totalscr = 0.4;
                else
                    totalscr = 0.3;
                qndiff = totalscr * share - qpaper;

                // Q1 közlemények
                if (rank === "Q1")
                    this.classify_pub(pub, score.q1lekfoly);
                if (rank !== "?")
                    thispub.type += ` WoS: ${rank}`;
                if (pub.ratings)
                    for (const r of pub.ratings)
                        if (r.otype === "SjrRating") {
                            thispub.type += ` SJR: ${r.ranking}`;
                            if (r.ranking === "D1") this.array_add(score.rD1Plot, pub.publishedYear, 1);
                            else if (r.ranking === "Q1") this.array_add(score.rQ1Plot, pub.publishedYear, 1);
                            else if (r.ranking === "Q2") this.array_add(score.rQ2Plot, pub.publishedYear, 1);
                            else if (r.ranking === "Q3") this.array_add(score.rQ3Plot, pub.publishedYear, 1);
                            else if (r.ranking === "Q4") this.array_add(score.rQ4Plot, pub.publishedYear, 1);
                            break;
                        }
                const norway = this.get_norway(pub);
                if (norway.length > 0) thispub.type += ` Norvég lista: ${norway}`;
                scijourconf = true;
            }
            else if (pub.fullPublication && pub.journal.reviewType!=="REVIEWED" && pub.subType && pub.subType.name==="Szakcikk") {
                //this.classify_pub(pub, score.nemlekfoly);
            } 
        }
        else if (pub.fullPublication && 
                (( pub.type.label==="Könyvrészlet" && pub.subType &&  pub.subType.label==="Konferenciaközlemény (Könyvrészlet)")
                || pub.type.label==="Egyéb konferenciaközlemény"
                )) {
            
            isbnmissing = ! (await this.has_isbn_issn(pub));
            minmeret = 4;
            if ((plength>=minmeret || this.props.options.short) && (!isbnmissing || this.props.options.noISBN)) {   
                this.classify_pub(pub, score.konfcikk);
                const totalscr = pub.foreignLanguage ? 0.2 : 0.1;
                qpaper = totalscr * share;
                score.konfcikkpubscr += qpaper;
                scijourconf = true;
            }
        }
        else if (pub.type.label==="Könyv") {
            minmeret = 10;
            if (plength>minmeret || this.props.options.short) {
                this.classify_pub(pub, score.konyv);
                let totalscr;
                if (plength>=100) {
                    if (pub.foreignLanguage)
                        totalscr = 2;
                    else
                        totalscr = 1;
                }
                else {
                    if (pub.foreignLanguage)
                        totalscr = 0.2*Math.floor(plength/10);
                    else
                        totalscr = 0.1*Math.floor(plength/10);
                }
                qbook = totalscr * share;
                score.konyvpubscr += qbook;
                scijourconf = true;
            }
        }
        else if (pub.type.label==="Könyvrészlet" && pub.subType && (pub.subType.label==="Könyvfejezet (Könyvrészlet)" || pub.subType.label==="Szaktanulmány (Könyvrészlet)")) {
            minmeret = 10;
            if (plength>=minmeret || this.props.options.short) {
                this.classify_pub(pub, score.konyvresz);
                let totalscr;
                if (plength>=100) {
                    if (pub.foreignLanguage)
                        totalscr = 2;
                    else
                        totalscr = 1;
                }
                else {
                    if (pub.foreignLanguage)
                        totalscr = 0.2*Math.floor(plength/10);
                    else
                        totalscr = 0.1*Math.floor(plength/10);
                }
                qbook = totalscr * share;
                score.konyvreszpubscr += qbook;
                scijourconf = true;
            }
        }
//        else if (pub.type.label==="Oltalmi formák") {
//            this.classify_pub(pub, score.oltalom);
//        }
        // identifiers
        if (scijourconf) {
            let indexed = false;
            let weben = false;
            if (pub.identifiers && pub.identifiers.length>0) {
                for (const id of pub.identifiers) {
                    if (id.source && id.source.type && id.source.type.label==="Indexelő adatbázis")
                        indexed = true;
                    if (id.source && id.source.label && (id.source.label==="DOI" || id.source.label==="WoS" || id.source.label==="Scopus" || id.source.label==="Teljes dokumentum"))
                        weben = true;
                }
                if (indexed)
                    this.classify_pub(pub, score.adatbazisban);
                if (weben)
                    this.classify_pub(pub, score.weben);
            }
        }
        // citations
        let row = -1, col = -1;
        if (pub.citations) {
            for (const c of pub.citations) {
                if (!c.published)
                    continue;
                if (this.props.date) {
                    const citdate = await this.get_citation_date(c);
                    if (!citdate || citdate>this.props.date)
                        continue;
                }                                    
                if (c.externalCitation) {
                    if (c.related.type.label==='Könyvrészlet' || c.related.type.label==='Könyv')
                        col = 0;
                    else if (c.related.type.label==='Folyóiratcikk')
                        col = 1;
                    else if (c.related.type.label==='Egyéb konferenciaközlemény')
                        col = 2;
                    else if (c.related.type.label==='Disszertáció')
                        col = 3;
                    else if (c.related.type.label==='Oltalmi formák')
                        col = 4;
                    else
                        col = 5;

                    if (!Object.keys(c.related).includes('nationalOrigin'))
                        row = 4;
                    else if (c.related.nationalOrigin===false) {
                        if (c.related.foreignEdition)
                            row = 0;
                        else
                            row = 1;
                    }
                    else {
                        if (c.related.foreignLanguage)
                            row = 2;
                        else
                            row = 3;
                    }
                    score.idezetek[row][col].count += 1;
                    score.idezetek[row][col].ids.push(c.mtid);
                    if (col<3) {
                        if (c.related.identifiers) {
                            for (const i of c.related.identifiers)
                                if (i.source.label === "WoS" || i.source.label === "Wos-CSCD (Chinese)") {
                                    score.wosidezet.count += 1;
                                    score.wosidezet.ids.push(c.mtid);
                                    this.array_add(score.iWosPlot, c.related.publishedYear, 1);
                                    break;
                                }
                            for (const i of c.related.identifiers)
                                if (i.source.label === "Scopus") {
                                    score.scopusidezet.count += 1;
                                    score.scopusidezet.ids.push(c.mtid);
                                    break;
                                }
                            for (const i of c.related.identifiers)
                                if (i.source.label === "Google scholar") {
                                    score.scholaridezet.count +=1;
                                    score.scholaridezet.ids.push(c.mtid);
                                    break;
                                }
                        }
                        thispub.iscore += 1;
                        if (c.related.publishedYear > 0)
                            this.array_add(score.iPlot, c.related.publishedYear, 1);
                    }
                    thispub.allcit += 1;
                }                
            }
        }

        if (minmeret>0 && plength===0)
            thispub.comment += "(Nincs terjedelem megadva!!!)";
        else if (minmeret>0 && plength<minmeret)
            thispub.comment += `(Túl rövid, ${plength} oldal!!!)`;
        if (pub.otype==="JournalArticle" && pub.journal && pub.journal.reviewType!=="REVIEWED")
            thispub.comment += "(Nem bírált!!!)";
        if (isbnmissing)
            thispub.comment += "(Nincs ISBN/ISSN szám megadva!!!)";
        
        thispub.qscore += qbook + qpaper;
        thispub.qnscore += qbook + qpaper + qndiff;

        score.iscore += thispub.iscore;
        score.osszesidezo += pub.citationCountWoOther;
        score.qpaper += qpaper;
        score.qpapernorm += qpaper + qndiff;
        score.qbook += qbook;
        score.osszespub += 1;

        this.array_add(score.qPaperPlot, pub.publishedYear, qpaper);
        this.array_add(score.qBookPlot, pub.publishedYear, qbook);
        this.array_add(score.pubsPlot, pub.publishedYear, 1);
        
        score.idezetidosor.push(pub.independentCitationCount);

        // compute hirsch index
        score.idezetidosor.sort((a,b) => b - a);
        score.hindex = 0;
        while (score.hindex < score.idezetidosor.length && score.hindex < score.idezetidosor[score.hindex])
            score.hindex++;

        finish_pub_scoring();
    }

    getMTMTPubLink(counter) {
        if (counter.count > 0)
            return <a href={"https://m2.mtmt.hu/api/publication?cite_type=2&size=5000&cond=mtid;in;"+counter.ids.join()}>{counter.count}</a>;
        else
            return 0;
    }

    getMTMTCitationLink(...counters) {
        const res = { count: 0, ids: [] };
        counters.forEach(cnt => {
            res.count += cnt.count;
            res.ids = res.ids.concat(cnt.ids);
        });
        if (res.count > 0)
            return <a href={"https://m2.mtmt.hu/api/citation?size=5000&cond=mtid;in;"+res.ids.join()}>{res.count}</a>;
        else
            return 0;
    }

    render() {

        if (this.state.iftabloading)
            return <div className="mtmtspinner">
                    <Spinner intent="primary" size="100" />
                    <p>További pontozást segítő adatok letöltése...</p>
                </div>;

        if (this.props.pubs.length === 0)
            return <NonIdealState 
                title = "Nem pontozható!"
                description = "A megadott azonosítójú felhasználó nem létezik, vagy üres a publikációs listája."
                icon = "warning-sign"
            />

        let sumid = { count: 0, ids: [] };
        for(let i=0; i<5; i++)
            for(let j=0; j<4; j++) {
                sumid.count += this.state.idezetek[i][j].count;
                sumid.ids = sumid.ids.concat(this.state.idezetek[i][j].ids);
            }

        let titles = "";
        if (this.props.author.degrees)
            titles = this.props.author.degrees.map(d => d.label).join(", ");


        // plot adatok formára hozása
        const plotYears = [];
        const pubsPlot = [];
        const qPaperPlot = [];
        const qBookPlot = [];
        const qPlot = [];
        const iPlot = [];
        const iWosPlot = [];
        const rD1Plot = [];
        const rQ1Plot = [];
        const rQ2Plot = [];
        const rQ3Plot = [];
        const rQ4Plot = [];
        const unranked = [];
        if (this.state.nextToProcess === this.props.pubs.length) {
            const firstYear = Math.min(...Object.keys(this.state.pubsPlot));
            const lastYear = Math.max(Math.max(...Object.keys(this.state.pubsPlot)), Math.max(...Object.keys(this.state.iPlot)));
            for (let i=firstYear; i<=lastYear; i++) {
                plotYears.push(i);
                pubsPlot.push(this.state.pubsPlot[i] ? this.state.pubsPlot[i] : 0);
                qPaperPlot.push(this.state.qPaperPlot[i] ? this.state.qPaperPlot[i] : 0);
                qBookPlot.push(this.state.qBookPlot[i] ? this.state.qBookPlot[i] : 0);
                iPlot.push(this.state.iPlot[i] ? this.state.iPlot[i] : 0);
                iWosPlot.push(this.state.iWosPlot[i] ? this.state.iWosPlot[i] : 0);
                rD1Plot.push(this.state.rD1Plot[i] ? this.state.rD1Plot[i] : 0);
                rQ1Plot.push(this.state.rQ1Plot[i] ? this.state.rQ1Plot[i] : 0);
                rQ2Plot.push(this.state.rQ2Plot[i] ? this.state.rQ2Plot[i] : 0);
                rQ3Plot.push(this.state.rQ3Plot[i] ? this.state.rQ3Plot[i] : 0);
                rQ4Plot.push(this.state.rQ4Plot[i] ? this.state.rQ4Plot[i] : 0);
            }
            // make plot data cummulative
            if (qPaperPlot.length > 0)
                qPlot[0] = qPaperPlot[0] + Math.min(3.0, qBookPlot[0]);
            for (let i=1; i<pubsPlot.length; i++) {
                pubsPlot[i] += pubsPlot[i-1];
                qPaperPlot[i] += qPaperPlot[i-1];
                qBookPlot[i] += qBookPlot[i-1];
                qPlot[i] = (qPaperPlot[i] + Math.min(3.0, qBookPlot[i])).toFixed(3);
                iPlot[i] += iPlot[i-1];
                iWosPlot[i] += iWosPlot[i-1];
                rD1Plot[i] += rD1Plot[i-1];
                rQ1Plot[i] += rQ1Plot[i-1];
                rQ2Plot[i] += rQ2Plot[i-1];
                rQ3Plot[i] += rQ3Plot[i-1];
                rQ4Plot[i] += rQ4Plot[i-1];
            }
            for (let i=0; i<pubsPlot.length; i++) {
                unranked.push(pubsPlot[i] - rD1Plot[i] - rQ1Plot[i] - rQ2Plot[i] - rQ3Plot[i] - rQ4Plot[i]);
            }
        }

        const progress = this.state.nextToProcess / this.props.pubs.length;
        
        // részletező rendezése
        const toPrint = [...this.state.publist];
        if (this.state.nextToProcess === this.props.pubs.length)
            toPrint.sort((a, b) => {
            if (this.state.sort === "q")
                return b.qscore - a.qscore;
            else if (this.state.sort === "qn")
                return b.qnscore - a.qnscore;
            else if (this.state.sort === "i")
                return b.iscore - a.iscore;
            else 
                return 0;
            });

        return <div>
          <div className="egyen-main-progress">
              <ProgressBar value={progress} animate={false} intent={progress<1 ? Intent.PRIMARY : Intent.SUCCESS} />
          </div>

          <div className="egyen-titulusok">
              <h1> <a href={"https://m2.mtmt.hu/api/author/"+this.props.selectedid}>{this.props.author.label}</a></h1>
              <h2> {titles} </h2>
              <h3> Lekérdezés dátuma: {(new Date()).toLocaleString("hu")} </h3>
              {this.props.date && <h3>Záródátum: {moment(this.props.date).format("YYYY. MM. DD.")}</h3>}
              { _.some(Object.values(_.pick(this.props.options, ["noISBN", "short", "share"]))) && 
                <div className="warnings">
                    <div className="warning"> Az alábbi pontozás nem követi pontosan az MTO szabályrendszerét! </div>
                    {this.props.options.noISBN && <div className="warning-detail">Az ISBN/ISSN számmal nem rendelkező publikációk is pontozásra kerültek.</div>}
                    {this.props.options.short && <div className="warning-detail">A túlságosan rövid közlemények is pontozásra kerültek.</div>}
                    {this.props.options.share && <div className="warning-detail">Egyenlő arány helyett az MTMT-ben rögzített szerzői részarány érvényesül.</div>}
                </div>
                
              }
          </div>

          <div className="egyen-main-panel">
            <div className="egyen-tablazat-panel">
                <div>
            <h3>Összefoglaló táblázat</h3>
            <table className="egyen-tablazat">
                <thead>
                <tr><th></th><th rowSpan="2">Pontszám</th><th>BME VIK</th><th colSpan="2">MTA-MTO Követelmény</th></tr>
                <tr><th></th><th>Habilitáció</th><th>A</th><th>B</th></tr>
                </thead>
                <tbody>
                <tr>
                    <td>Q szám összesen:</td><td>{(this.state.qpaper + Math.min(3.0, this.state.qbook)).toFixed(3)}</td><td>8</td><td>12</td><td>16</td>
                </tr>
                <tr>
                    <td>Q szám cikkekből:</td><td>{this.state.qpaper.toFixed(3)}</td><td></td><td>min. 6</td><td>min. 8</td>
                </tr>
                <tr>
                    <td>Q szám könyvből:</td><td>{this.state.qbook.toFixed(3)}</td><td></td><td>max. 3</td><td>max. 3</td>
                </tr>
                <tr>
                    <td>I szám:</td><td>{this.state.iscore}</td><td>40</td><td>90</td><td>200</td>
                </tr>
                <tr>
                    <td>I szám WoS idézetekből:</td><td>{this.state.wosidezet.count}</td><td>20</td><td>45</td><td>100</td>
                </tr>
                <tr>
                    <td>Impakt faktor:</td><td>{this.state.if.toFixed(3)}</td><td></td><td></td><td></td>
                </tr>
                <tr>
                    <td>Relatív impakt faktor:</td><td>{this.state.relif.toFixed(3)}</td><td>2</td><td>3</td><td>4</td>
                </tr>
                <tr>
                    <td>IF-es cikkek száma:</td><td>{this.state.iflekfoly.kulfoldon.count + this.state.iflekfoly.moinyelv.count + this.state.iflekfoly.momagyar.count}</td><td>4</td><td>6</td><td>8</td>
                </tr>
                <tr>
                    <td>Egyszerzős IF-es cikkek:</td><td>{this.state.egysziflekfoly.kulfoldon.count + this.state.egysziflekfoly.moinyelv.count + this.state.egysziflekfoly.momagyar.count}</td><td></td><td>2</td><td>2</td>
                </tr>
                <tr>
                    <td>H index:</td><td>{this.state.hindex}</td><td>4</td><td>5</td><td>7</td>
                </tr>
                <tr>
                    <td>Összes idéző:</td><td>{this.state.osszesidezo}</td><td></td><td></td><td></td>
                </tr>
                <tr>
                    <td>Összes publikáció:</td><td>{this.state.osszespub}</td><td></td><td></td><td></td>
                </tr>
                </tbody>
            </table>
            <p><b>Figyelem!</b> A Tud-O-Méter csak az 1993-ban, vagy később megjelent publikációk impakt faktorát ismeri, 
                az ennél korábbi impakt faktoros folyóiratokat kézzel kell hozzávenni a pontozáshoz!</p>
            </div>

            {this.props.options.normif && <>
                <div>
                    <table className="egyen-tablazat">
                        <thead>
                        <tr><th>Normalizált metrika</th><th>Pontszám</th></tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Normalizált Q szám:</td><td>{this.state.qpapernorm.toFixed(3)}</td>
                        </tr>
                        <tr>
                            <td>Normalizált IF:</td><td>{this.state.ifnorm.toFixed(3)}</td>
                        </tr>
                        <tr>
                            <td>Normalizált relatív IF:</td><td>{this.state.relifnorm.toFixed(3)}</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>Ezen mennyiségek számolásakor az impakt faktorok leosztásra kerültek a szakterületi medián impakt faktorokkal.</p>
                </div>
                </>}
                <div className="page-break"></div>
            </div>
            
            <div className="egyen-plot-panel">
            <div className="egyen-plot">
                    <Bar options={{ responsive: true, maintainAspectRatio: false }} data={{
                        labels: plotYears,
                        datasets: [
                            {
                                label: "Q pontszám",
                                backgroundColor: '#2196f3',
                                data: qPlot
                            },
                            {
                                label: '"Habilitáció"',
                                data: new Array(plotYears.length).fill(8),
                                type: "line",
                                fill: "false",
                                borderColor: '#000000',
                                pointRadius: "0",
                                borderWidth: "1"
                            },
                            {
                                label: '"A"',
                                data: new Array(plotYears.length).fill(12),
                                type: "line",
                                fill: "false",
                                borderColor: '#000000',
                                pointRadius: "0",
                                borderWidth: "1",
                                borderDash: [5, 4]
                            },
                            {
                                label: '"B"',
                                data: new Array(plotYears.length).fill(16),
                                type: "line",
                                fill: "false",
                                borderColor: '#000000',
                                pointRadius: "0",
                                borderWidth: "1",
                                borderDash: [2, 2]
                            }
                        ]
                    }}/>
                </div>
                <div className="egyen-plot">
                    <Bar options={{ responsive: true, maintainAspectRatio: false }} data={{
                        labels: plotYears,
                        datasets: [
                            {
                                label: "I pontszám",
                                backgroundColor: '#f44336',
                                data: iPlot
                            },
                            {
                                label: '"Habilitáció"',
                                data: new Array(plotYears.length).fill(40),
                                type: "line",
                                fill: "false",
                                borderColor: '#000000',
                                pointRadius: "0",
                                borderWidth: "1"
                            },
                            {
                                label: '"A"',
                                data: new Array(plotYears.length).fill(90),
                                type: "line",
                                fill: "false",
                                borderColor: '#000000',
                                pointRadius: "0",
                                borderWidth: "1",
                                borderDash: [5, 4]
                            },
                            {
                                label: '"B"',
                                data: new Array(plotYears.length).fill(200),
                                type: "line",
                                fill: "false",
                                borderColor: '#000000',
                                pointRadius: "0",
                                borderWidth: "1",
                                borderDash: [2, 2]
                            }
                        ]
                    }}/>
                </div>
                <div className="egyen-plot">
                    <Bar options={{ responsive: true, maintainAspectRatio: false }} data={{
                        labels: plotYears,
                        datasets: [
                            {
                                label: "I Wos pontszám",
                                backgroundColor: '#ff9800',
                                data: iWosPlot
                            },
                            {
                                label: '"Habilitáció"',
                                data: new Array(plotYears.length).fill(20),
                                type: "line",
                                fill: "false",
                                borderColor: '#000000',
                                pointRadius: "0",
                                borderWidth: "1"
                            },
                            {
                                label: '"A"',
                                data: new Array(plotYears.length).fill(45),
                                type: "line",
                                fill: "false",
                                borderColor: '#000000',
                                pointRadius: "0",
                                borderWidth: "1",
                                borderDash: [5, 4]
                            },
                            {
                                label: '"B"',
                                data: new Array(plotYears.length).fill(100),
                                type: "line",
                                fill: "false",
                                borderColor: '#000000',
                                pointRadius: "0",
                                borderWidth: "1",
                                borderDash: [2, 2]
                            }
                        ]
                    }}/>
                </div>
                <div className="egyen-plot">
                    <Bar options={{ 
                            responsive: true, 
                            maintainAspectRatio: false, 
                            scales: { x: { ticks: { minRotation: 45 } , stacked: true }, y: { stacked: true } },
                            interaction: {
                                intersect: false,
                                mode: 'index',
                            },
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: (tooltipItem) => {
                                            const label = tooltipItem.dataset.label || "";
                                            if (label === "Publikációk száma")
                                                return `${label}: ${pubsPlot[tooltipItem.dataIndex]}`;
                                            else
                                                return `${label}: ${tooltipItem.formattedValue}`;
                                        }
                                    }
                                }
                            }
                        }} data={{
                        labels: plotYears,
                        datasets: [
                            {
                                label: "D1",
                                backgroundColor: '#9CCC65',
                                data: rD1Plot
                            },
                            {
                                label: "Q1",
                                backgroundColor: '#ba68c8',
                                data: rQ1Plot
                            },
                            {
                                label: "Q2",
                                backgroundColor: '#ef5350',
                                data: rQ2Plot
                            },
                            {
                                label: "Q3",
                                backgroundColor: '#03a9f4',
                                data: rQ3Plot
                            },
                            {
                                label: "Q4",
                                backgroundColor: '#ff9800',
                                data: rQ4Plot
                            },
                            {
                                label: "Publikációk száma",
                                backgroundColor: '#4caf50',
                                data: unranked
                            }
                        ]
                    }} />
                </div>
            </div>
        </div>        
        <div className="page-break"></div>
        <div className="eloterjesztoi-panel">
            <h3>A kérelmező publikációs és alkotási teljesítménye (Q-szám)</h3>
            <table className="eloterjesztoi-tabla">
                <thead>
                <tr>
                    <th rowSpan="3">Publikációk</th>
                    <th rowSpan="2">Külföldön</th>
                    <th colSpan="2">Magyarországon</th>
                    <th></th>
                </tr>
                <tr>
                <th>idegen nyelven</th>
                <th>magyarul</th>
                <th></th>
                </tr>
                <tr>
                <th colSpan="3">megjelent közlemények száma</th>
                <th>pontszám</th>
                </tr>
                </thead>
                <tbody>
                <tr><td className="row-label">Tudományos folyóiratcikk</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr><td className="row-label">&nbsp;&nbsp;-lektorált</td>
                <td>{this.getMTMTPubLink(this.state.lekfoly.kulfoldon)}</td>
                <td>{this.getMTMTPubLink(this.state.lekfoly.moinyelv)}</td>
                <td>{this.getMTMTPubLink(this.state.lekfoly.momagyar)}</td>
                <td>{this.state.folypubscr.toFixed(3)}</td>
                </tr>                    
                <tr><td className="row-label">&nbsp;&nbsp;&nbsp;&nbsp;-lektorált, WoS Q1 rangú</td>
                <td>{this.getMTMTPubLink(this.state.q1lekfoly.kulfoldon)}</td>
                <td>{this.getMTMTPubLink(this.state.q1lekfoly.moinyelv)}</td>
                <td>{this.getMTMTPubLink(this.state.q1lekfoly.momagyar)}</td>
                <td></td>
                </tr>                    
                <tr><td className="row-label">&nbsp;&nbsp;&nbsp;&nbsp;-lektorált, IF-ral</td>
                <td>{this.getMTMTPubLink(this.state.iflekfoly.kulfoldon)}</td>
                <td>{this.getMTMTPubLink(this.state.iflekfoly.moinyelv)}</td>
                <td>{this.getMTMTPubLink(this.state.iflekfoly.momagyar)}</td>
                <td></td>
                </tr>                    
                <tr><td className="row-label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-ebből egyszerzős</td>
                <td>{this.getMTMTPubLink(this.state.egysziflekfoly.kulfoldon)}</td>
                <td>{this.getMTMTPubLink(this.state.egysziflekfoly.moinyelv)}</td>
                <td>{this.getMTMTPubLink(this.state.egysziflekfoly.momagyar)}</td>
                <td></td>
                </tr>                    
                <tr><td className="row-label">Konferenciacikk (min. 4 oldal)<br/>konferenciakötetben,<br/>folyóiratban, könyvrészletben</td>
                <td>{this.getMTMTPubLink(this.state.konfcikk.kulfoldon)}</td>
                <td>{this.getMTMTPubLink(this.state.konfcikk.moinyelv)}</td>
                <td>{this.getMTMTPubLink(this.state.konfcikk.momagyar)}</td>
                <td>{this.state.konfcikkpubscr.toFixed(3)}</td>
                </tr>              
                <tr className="main-row"><td className="row-label bold-text">Tudományos cikk összesen</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{(this.state.folypubscr+this.state.konfcikkpubscr).toFixed(3)}</td>
                </tr>                              
                <tr><td className="row-label">Tudományos könyv,<br/>könyvrészlet szerzőként</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr><td className="row-label">&nbsp;&nbsp;-könyv</td>
                <td>{this.getMTMTPubLink(this.state.konyv.kulfoldon)}</td>
                <td>{this.getMTMTPubLink(this.state.konyv.moinyelv)}</td>
                <td>{this.getMTMTPubLink(this.state.konyv.momagyar)}</td>
                <td>{this.state.konyvpubscr.toFixed(3)}</td>
                </tr>                              
                <tr><td className="row-label">&nbsp;&nbsp;-könyvrészlet</td>
                <td>{this.getMTMTPubLink(this.state.konyvresz.kulfoldon)}</td>
                <td>{this.getMTMTPubLink(this.state.konyvresz.moinyelv)}</td>
                <td>{this.getMTMTPubLink(this.state.konyvresz.momagyar)}</td>
                <td>{this.state.konyvreszpubscr.toFixed(3)}</td>
                </tr>                              
                <tr className="main-row"><td className="row-label bold-text">Tudományos könyv összesen</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{(this.state.konyvpubscr+this.state.konyvreszpubscr).toFixed(3)}</td>
                </tr>                              
                <tr><td className="row-label">Internetes adatbázisban<br/>megtalálható</td>
                <td>{this.getMTMTPubLink(this.state.adatbazisban.kulfoldon)}</td>
                <td>{this.getMTMTPubLink(this.state.adatbazisban.moinyelv)}</td>
                <td>{this.getMTMTPubLink(this.state.adatbazisban.momagyar)}</td>
                <td></td>
                </tr>                              
                <tr><td className="row-label">Teljes szöveggel elérhető a<br/>Weben</td>
                <td>{this.getMTMTPubLink(this.state.weben.kulfoldon)} (?)</td>
                <td>{this.getMTMTPubLink(this.state.weben.moinyelv)} (?)</td>
                <td>{this.getMTMTPubLink(this.state.weben.momagyar)} (?)</td>
                <td></td>
                </tr>                              
             </tbody>
            </table>
            <div className="comment">
            <span className="screen-only">Megjegyzések: 
            Az MTMT a tudománymetriai táblázatban a minimális terjedelmet (mely konferenciacikknél 4 oldal, könyvnél, könyvrészletnél 10 oldal) és az ISBN követelményt nem veszi figyelembe, míg a Tud-O-Méter
            az MTA MTO előírásainak megfelelően pontoz.</span>
            <br/>
            {/*<span className="screen-only">Arra, hogy a "Teljes szöveggel elérhető a Weben" kategóriát hogy állítja elő az MTMT, még nem tudtam rájönni. Érzésem szerint a DOI, WoS, Scopus vagy a Teljes Dokumentum azonosítóval 
            ellátott publikációkat számolja bele, és a jelek szerint a nem pontozott tételeket (recenziót, utánközlést, stb., olyan kiadványokat, melyben az illető nem szerző, csak szerkesztő) is figyelembe veszi. 
                A Tud-O-Méter hasonlóan számol, de a nem pontozott tételeket nem veszi számításba.</span>
            <span className="screen-only">A folyóiratok rangjának meghatározása az MTA MTO előírásainak megfelelően a WoS adatbázis alapján történik, nem pedig az MTMT-ben alapból megtalálható SJR Scopus rang adatok alapján.</span>*/}
            </div>
            <h3>A kérelmező idézettsége (I-szám)</h3>
            <table className="eloterjesztoi-tabla">
                <thead>
                <tr>
                    <th>Hivatkozások darabszáma</th>
                    <th>könyvben,<br/>fejezetben</th>
                    <th>folyó-<br/>iratban</th>
                    <th>konf.<br/>cikkben</th>
                    <th>érteke-<br/>zésben</th>
                    <th>eddig<br/>összesen</th>
                    <th>oltalmi<br/>formában</th>
                    <th>további köz-<br/>leményben</th>
                </tr>
                </thead>
                <tbody>
                <tr><td className="row-label">csak külföldi szerző, külföldi kiadás</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][0])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][1])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][2])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][3])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][0], this.state.idezetek[0][1], this.state.idezetek[0][2], this.state.idezetek[0][3])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][4])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][5])}</td>
                </tr>
                <tr><td className="row-label">csak külföldi szerző, hazai kiadás<br/>vagy nincs megjelölve</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[1][0])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[1][1])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[1][2])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[1][3])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[1][0], this.state.idezetek[1][1], this.state.idezetek[1][2], this.state.idezetek[1][3])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[1][4])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[1][5])}</td>
                </tr>
                <tr><td className="row-label">hazai szerző (is) itthon (vagy nincs<br/>megjelölve hol)</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr><td className="row-label">&nbsp;&nbsp;-idegen nyelven</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[2][0])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[2][1])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[2][2])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[2][3])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[2][0], this.state.idezetek[2][1], this.state.idezetek[2][2], this.state.idezetek[2][3])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[2][4])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[2][5])}</td>
                </tr>
                <tr><td className="row-label">&nbsp;&nbsp;-magyarul</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[3][0])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[3][1])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[3][2])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[3][3])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[3][0], this.state.idezetek[3][1], this.state.idezetek[3][2], this.state.idezetek[3][3])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[3][4])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[3][5])}</td>
                </tr>
                <tr><td className="row-label">nincs megadva, hogy van-e hazai<br/>szerző az idézők között</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[4][0])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[4][1])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[4][2])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[4][3])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[4][0], this.state.idezetek[4][1], this.state.idezetek[4][2], this.state.idezetek[4][3])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[4][4])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[4][5])}</td>
                </tr>
                <tr className="main-row"><td className="row-label bold-text">Összesen</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][0], this.state.idezetek[1][0], this.state.idezetek[2][0], this.state.idezetek[3][0], this.state.idezetek[4][0])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][1], this.state.idezetek[1][1], this.state.idezetek[2][1], this.state.idezetek[3][1], this.state.idezetek[4][1])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][2], this.state.idezetek[1][2], this.state.idezetek[2][2], this.state.idezetek[3][2], this.state.idezetek[4][2])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][3], this.state.idezetek[1][3], this.state.idezetek[2][3], this.state.idezetek[3][3], this.state.idezetek[4][3])}</td>
                <td>{this.getMTMTCitationLink(sumid)}</td>                
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][4], this.state.idezetek[1][4], this.state.idezetek[2][4], this.state.idezetek[3][4], this.state.idezetek[4][4])}</td>
                <td>{this.getMTMTCitationLink(this.state.idezetek[0][5], this.state.idezetek[1][5], this.state.idezetek[2][5], this.state.idezetek[3][5], this.state.idezetek[4][5])}</td>
                </tr>
             </tbody>
            </table>
            <div>Független WoS (SCI) hivatkozásai: {this.getMTMTCitationLink(this.state.wosidezet)}</div>
            <div>Független Scopus hivatkozásai: {this.getMTMTCitationLink(this.state.scopusidezet)}</div>
            <div>Független Google Scholar hivatkozásai: {this.getMTMTCitationLink(this.state.scholaridezet)}</div>
            <div className="comment">
                Megjegyzés:&nbsp;
                <span className="screen-only"> Az MTMT a független idézőkbe beleszámítja az oltalmi formákban megjelent idézeteket is, a Tud-O-Méter a MTA MTO szabályzatának megfelelően ezt nem teszi meg.</span>
                A Tud-O-Méter a disszertációkban megjelent idézeteket nem számolja bele az "I" számba, mivel a szabályzat nem minden típusú értekezést fogad el idézőként.
            </div>
            </div>
            <div className="page-break"></div>
            <div>
            <h3>Pontozás részletező</h3>
            <table className="pontozo-tabla">
                <thead>
                <tr>
                    <th onClick={() => this.setState({sort: "year"})}>Publikáció</th>
                    <th onClick={() => this.setState({sort: "q"})}>Q pont</th>
                    {this.props.options.normif && <th onClick={() => this.setState({sort: "qn"})}>Q norm.</th>}
                    <th onClick={() => this.setState({sort: "i"})}>I pont</th>
                </tr>
                </thead>
                <tbody>
                {toPrint.map((value, index) => {{
                    let className = "";
                    if (value.type.includes("Norvég lista: 0")) className = "norveg-0";
                    else if (value.type.includes("Norvég lista: X")) className = "norveg-x";
                    else if (value.type.includes("Norvég lista: 2")) className = "norveg-2";
                    return <tr key={index} className={className}>
                        <td className="row-label"><a href={"https://m2.mtmt.hu"+value.link}>{value.title}</a><br/>{value.type} {value.comment}</td>
                        <td>{value.qscore.toFixed(3)}</td>
                        {this.props.options.normif && <td>{value.qnscore.toFixed(3)}</td>}
                        <td>{value.iscore}</td></tr>;
                    }})}
                </tbody>
            </table>
            {this.props.options.norway && (
            <div className="comment"><span className="screen-only">A táblázatban piros háttér jelzi a norvég lista szerint 0-ás, narancssárga az X, zöld a 2-es besorolású folyóiratcikkeket. A besorolás a pontozást nem befolyásolja.</span></div>
            )}
            </div>
        </div>
    }
  }