import React from 'react';
import { Card, Elevation } from "@blueprintjs/core";

export default class MTAMTOScoringInfo extends React.Component {

    render() {
        return <Card className="valtozasok" elevation={Elevation.TWO}>
            <p><b>Legutóbbi változások:</b></p>
            <p>v2.26 (2024. augusztus 23.)</p>
            <ul>
            <li>A norvég lista szerinti minősítés kijelzésének opcionálissá tétele</li>
            </ul>
            <p>v2.25 (2024. július 23.)</p>
            <ul>
            <li>2023-as impakt faktorok figyelembe vétele</li>
            <li>A norvég lista szerinti minősítés kijelzése a listában (a megjelenés évében, ha van rendelkezésre álló adat)</li>
            <li>A különböző rangú folyóiratok számának feltüntetése a grafikonon</li>
            </ul>
            <p>v2.24 (2023. július 7.)</p>
            <ul>
            <li>2022-es impakt faktorok figyelembe vétele</li>
            <li>A Wos és az SJR kvartilisek kijelzése a listában</li>
            </ul>
            <p>v2.23 (2022. augusztus 26.)</p>
            <ul>
            <li>2021-es impakt faktorok figyelembe vétele</li>
            </ul>
            <p>v2.22 (2021. december 22.)</p>
            <ul>
            <li>Alkalmazkodás az MTMT megváltozott viselkedéséhez</li>
            </ul>
            <p>v2.21 (2021. október 22.)</p>
            <ul>
            <li>Új opció: az impakt faktorok helyett szakterületi mediánnal normalizált impakt faktorok használata (1999 utáni közleményekre, legalább 2 éve IF-es folyóiratok esetén)</li>
            <li>A részletező táblázat az oszlopfejlécre kattintva rendezhető</li>
            </ul>
            <p>v2.20 (2021. szeptember 20.)</p>
            <ul>
            <li>WoS szerinti folyóirat rangok kijelzése a részletezőben és a Q1 folyóiratok összegzése az előterjesztői táblázatban</li>
            </ul>
            <p>v2.19 (2021. július 19.)</p>
            <ul>
            <li>2020-as impakt faktorok figyelembe vétele</li>
            </ul>
            <p>v2.18 (2021. január 27.)</p>
            <ul>
            <li>Linkek a részletező táblázat számai mögött</li>
            <li>Optimalizált formázás és tördelés a böngészőből pdf file-ba nyomtatás esetén</li>
            <li>Kisebb, pontozást nem érintő hibajavítások</li>
            </ul>
            <p>v2.17 (2020. november 24.)</p>
            <ul>
            <li>Idézetek pontosabb számolása (a nem publikált idézők kiszűrésével)</li>
            <li>Az MTO pontozásától való eltérések opcionális engedélyezése</li>
            <li>Kisebb kozmetikai változtatások</li>
            </ul>
            <p>v2.16 (2020. október 27.)</p>
            <ul>
            <li>IF-ek pontosabb kezelése</li>
            </ul>
            <p>v2.15 (2020. szeptember 24.)</p>
            <ul>
            <li>Régebbi IF-ek kezelése</li>
            <li>Kisebb kozmetikai változtatások</li>
            </ul>
            <p>v2.14 (2020. július 9.)</p>
            <ul>
            <li>Pontozás URL-ben átadott MTMT azonosító alapján<br/>(http://www.hit.bme.hu/~ghorvath/tudometer/mtoscoring/12345678 formátumban)</li>
            <li>Kódtisztítás, apró hibák javítása</li>
            </ul>
            <p>v2.13 (2020. július 1.)</p>
            <ul>
            <li>2019-es impakt faktorok figyelembe vétele</li>
            <li>Kódtisztítás</li>
            </ul>
            <p>v2.12 (2020. június 25.)</p>
            <ul>
            <li>Jobb IF keresés a folyóiratnevek alternatív rövidítései alapján</li>
            <li>Mélyebb ISBN és ISSN keresés (lassabb, de több konferenciacikk válik pontozhatóvá)</li>
            </ul>
            <p>v2.11</p>
            <ul>
            <li>Változások listájának megjelenítése</li>
            <li>Hibajavítás: rövid közlemények és sokszerzős vagy csoportos szerzőségű szakcikkek elfogadása folyóiratcikként</li>
            <li>Hibajavítás: csak ISBN számmal rendelkező konferenciacikkek vehetők figyelembe (ez sokakat érint!)</li>
            </ul>
            <p>v2.10</p>
            <ul>
            <li>Hibajavítás: Wos-CSCD (Chinese) idézők figyelembe vétele WoS hivatkozásként</li>
            <li>Hiányos rekordok jobb kezelése</li>
            </ul>
            <p>v2.9</p>
            <ul>
            <li>Figyelmeztetés, hogy impakt faktorok csak 1997-től állnak rendelkezésre</li>
            <li>Hibajavítás: magyar nyelvű konferenciacikkekre csak 0.1 pont jár</li>
            </ul>
            <p>v2.8</p>
            <ul>
            <li>Átírás az új előterjesztői táblázatnak megfelelően</li>
            </ul>
        </Card>;
    }
}

//<li>Pontozás URL-ben átadott MTMT azonosító alapján<br/>(http://www.hit.bme.hu/~ghorvath/tudometer/mtoscoring/12345678 formátumban)</li>
