import React from 'react';
import './App.css';
import { Icon } from "@blueprintjs/core";
import { BrowserRouter, Routes, Route, NavLink, Navigate } from "react-router-dom";
import MTAMTOScoringApp from './MTAMTOScoringApp';
import PhDScoringApp from './PhDScoringApp';

export default class App extends React.Component {

    render() {
        return (
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <div className="App">
                    <div className="App-header">
                        <div>
                            <h1 className="App-title">Tud-O-Méter</h1>
                        </div>
                        <img src="./vik-logo.png" alt="logo" />
                    </div>
                        <div className="App-menu-row">
                            <NavLink to="/mtoscoring" className={(navData) => "App-menu-item" + (navData.isActive ? " App-menu-item-active" : "")}>
                                <span><Icon icon="timeline-bar-chart"/> MTA MTO Pontozás</span>
                            </NavLink>
                            {/*<a href="https://research.vik.bme.hu/eredmenyesseg/publikacios-graf/" className="App-menu-item">
                                <span><Icon icon="layout"/> VIK Publikációs Gráf</span>
                            </a>*/}
                            {/*<NavLink to="/vikpubgraph" className="App-menu-item" activeClassName="App-menu-item-active">
                                <span><Icon icon="layout"/> VIK Publikációs Gráf</span>
                            </NavLink>*/}
                            {/*<NavLink to="/phdscoring" className="App-menu-item" activeClassName="App-menu-item-active">
                                <span><Icon icon="layout"/> VIK PhD Pontozás </span>
                            </NavLink>*/}
                        </div>
                    <Routes>
                        <Route exact path="/" element={<Navigate to="/mtoscoring" replace />} />
                        <Route exact path="/mtoscoring" element={<MTAMTOScoringApp />} />
                        <Route exact path="/mtoscoring/:mtmtid" element={<MTAMTOScoringApp />} />
                        <Route exact path="/phdscoring" element={<PhDScoringApp />} />
                        <Route exact path="/phdscoring/:mtmtids" element={<PhDScoringApp />} />
                    </Routes>
                </div>
            </BrowserRouter>
        );
    }
}
