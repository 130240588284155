import React from 'react';
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import MTMTAuthorChooser from "./MTMTAuthorChooser";
import { Button, Card, Checkbox, Intent, Elevation, Collapse, Icon } from "@blueprintjs/core";
import _ from "lodash";

export default class MTAMTOInputParams extends React.Component {

    constructor(props) {
        super(props);  
        this.state = { mtmtid: '', date: null, dateEnabled: false, advanced: false, options: this.props.options};
    }

    componentDidMount() {        
        if (this.props.author && this.props.author.mtid !== this.state.mtmtid)
            this.initializeWithAuthor();
    }

    componentDidUpdate(prevProps) {        
        const presentMTMTId = this.props.author ? this.props.author.mtid: "";
        const prevMTMTId = prevProps.author ? prevProps.author.mtid: "";        
        if (presentMTMTId !== prevMTMTId || !_.isEqual(this.props.options, prevProps.options))
            this.initializeWithAuthor();
    }

    initializeWithAuthor() {
        this.setState({ 
            mtmtid: this.props.author ? this.props.author.mtid : "", 
            options: this.props.options,
            advanced: this.state.advanced || _.some(Object.values(_.omit(this.props.options, "norway")))
        });
    }

    render() {
        return <Card className="egyen-kereso" elevation={Elevation.TWO}>
            <MTMTAuthorChooser 
                title="Név"
                author={this.props.author} 
                mtmtid={this.state.mtmtid} 
                onMTMTIdChanged={mtid => this.setState({mtmtid: mtid})}
            />
            <div className="egyen-kereso-datum">
                <Checkbox 
                    checked={this.state.dateEnabled} 
                    label="Záró dátum megadása, előterjesztőknek (lassú lesz!)" 
                    onChange={() => this.setState({dateEnabled: !this.state.dateEnabled})} 
                />
            </div>
            {this.state.dateEnabled &&
                <div className="egyen-kereso-elem egyen-kereso-behuzott">
                    <span>Eddig a dátumig: &nbsp;</span>
                    <DateInput 
                        value={this.state.date} 
                        onChange={(date) => this.setState({ date })}
                        formatDate={date => moment(date).format("YYYY/MM/DD")}
                        parseDate={str => moment(str, "YYYY/MM/DD").toDate()}
                        placeholder={"ÉV/HÓ/NAP"}
                        className={"egyen-kereso-input"}
                    />
                </div>
            }
            <div className="egyen-kereso-datum">
                <Checkbox 
                    checked={this.state.options.norway} 
                    label="Cikkek norvég lista szerinti besorolásának megjelenítése" 
                    onChange={() => this.setState({options: _.set(_.cloneDeep(this.state.options), 'norway', !this.state.options.norway)})} 
                />
            </div>            
            <div className="expander-header">
                <div className={`expander-header-right ${this.state.advanced ? "expander-header-open": "expander-header-closed"}`}>
                    <Button minimal="true" icon={(<Icon icon="caret-right" iconSize={Icon.SIZE_LARGE} />)}  small="false" 
                            onClick={() => this.setState({advanced: !this.state.advanced}) }
                    />
                </div>
                <div ref={this.titleRef} className="expander-header-title" onClick={() => this.setState({advanced: !this.state.advanced}) }>
                    Eltérés az MTO követelményektől
                </div>
            </div>
            <Collapse isOpen={this.state.advanced}>
                <div className="advanced-mto-panel">
                    <Checkbox 
                        checked={this.state.options.noISBN} 
                        label="ISBN számmal nem rendelkező konferenciacikkek figyelembe vétele" 
                        onChange={() => this.setState({options: _.set(_.cloneDeep(this.state.options), 'noISBN', !this.state.options.noISBN) })} 
                    />
                    <Checkbox 
                        checked={this.state.options.short} 
                        label="Túlságosan rövid közlemények figyelembe vétele" 
                        onChange={() => this.setState({options: _.set(_.cloneDeep(this.state.options), 'short', !this.state.options.short) })} 
                    />
                    <Checkbox 
                        checked={this.state.options.share} 
                        label="MTMT-ben rögzített szerzői arányok figyelembe vétele" 
                        onChange={() => this.setState({options: _.set(_.cloneDeep(this.state.options), 'share', !this.state.options.share) })} 
                    />
                    <Checkbox 
                        checked={this.state.options.normif} 
                        label="Szakterületi mediánnal normalizált impakt faktorok használata" 
                        onChange={() => this.setState({options: _.set(_.cloneDeep(this.state.options), 'normif', !this.state.options.normif) })} 
                    />
                </div>
            </Collapse>
            <div>
                <Button 
                    text="Pontozás" 
                    intent={Intent.PRIMARY} 
                    onClick={() => this.props.onAuthorSelected(this.state.mtmtid, this.state.dateEnabled ? this.state.date : false, _.cloneDeep(this.state.options))} 
                    className="scoring-button"
                />
            </div>
        </Card>;
    }
}