import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { createRoot } from 'react-dom/client';

window.getMTMTObject = async function(object, params) {
    const paramStr = params ? params : "";
    const response = await fetch(`https://m2.mtmt.hu${object}?${paramStr}&format=json`);
    if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
    return await response.json();
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
