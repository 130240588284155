import React from 'react';
import { Spinner } from "@blueprintjs/core";
import MTAMTOScientometer from './MTAMTOScientometer';
import MTAMTOInputParams from './MTAMTOInputParams';
import MTAMTOScoringInfo from './MTAMTOScoringInfo';
import queryString from 'query-string';
import _ from "lodash";
import withLocation from './withlocation';

class MTAMTOScoringApp extends React.Component {

    constructor(props) {
        super(props);

        this.defaultOptions = { noISBN: false, share: false, short: false, normif: false, norway: false };
        this.state = { pubs: [], author: null, selectedid: null, loading: false, progress: 0, date: false, options: this.defaultOptions };
        this.handleAuthorSelected = this.handleAuthorSelected.bind(this);
    }

    optionsFromQueryString() {
        const sParams = queryString.parse(this.props.location.search);
        const opts = _.cloneDeep(this.defaultOptions);
        for (const optName in opts) 
            opts[optName] = Boolean(Number(sParams[optName]));
        return opts;
    }

    queryStringFromOptions(options) {
        let searchStr = "?";
        for (const optName in options) 
            if (options[optName])
                searchStr += optName+"=1&";
        if (searchStr.endsWith("&"))
            searchStr = searchStr.substring(0, searchStr.length-1);
        if (searchStr.length === 1)
            searchStr = "";
        return searchStr;
    }

    componentDidMount() {        
        if (this.props.params.mtmtid)
            this.loadPublications(this.props.params.mtmtid, false, this.optionsFromQueryString());
    }

    componentDidUpdate(prevProps) {        
        if (this.props.params.mtmtid && this.props.params.mtmtid !== prevProps.params.mtmtid)
            this.loadPublications(this.props.params.mtmtid, false, this.optionsFromQueryString());
    }

    handleAuthorSelected(mtmtid, date, options) {
        this.loadPublications(mtmtid, date, options);
        this.props.navigate("/mtoscoring/"+mtmtid+this.queryStringFromOptions(options));
    }

    async loadPublications(mtmtid, date, options) {
        this.setState({loading: true, progress: 0});
        const selectedid = String(mtmtid);
        try {            
            let pubData = [];
            let errorCounter = 0;
            let page = 1;
            while (true) {
                try {
                    const nextData = await window.getMTMTObject("/api/publication", `cond=published;eq;true&cond=core;eq;true&cond=authors.mtid;eq;${selectedid}&sort=publishedYear,desc&sort=firstAuthor,asc&size=20&fields=citations,journal:1&labelLang=hun&page=${page}`);
                    pubData = pubData.concat(nextData.content);
                    this.setState({progress: nextData.paging.number/nextData.paging.totalPages});
                    if (!nextData.paging.last)
                        page++;
                    else 
                        break;
                }
                catch (err) {
                    console.log(`${Date.now()} When loading page ${page}: `, err);
                    errorCounter++;
                    if (errorCounter < 30)
                        await new Promise(resolve => setTimeout(() => resolve(), 250));
                }
            }
            console.log("Data fetched from MTMT.");
            const authorData = await window.getMTMTObject(`/api/author/${selectedid}`);
            this.setState({
                pubs: pubData, 
                author: authorData.content,
                selectedid: selectedid, 
                loading: false, 
                date: date,
                options: options
            });
        } catch (err) {
            console.log(err);
            this.setState({
                pubs: [], 
                author: null,
                selectedid: selectedid, 
                loading: false, 
                date: false,
                options: this.defaultOptions
            });
        }
    }

    render() {
        return <div>
            <MTAMTOInputParams onAuthorSelected={this.handleAuthorSelected} author={this.state.author} options={this.state.options} />

            {!this.state.loading && this.state.selectedid === null && <MTAMTOScoringInfo />}  
            {!this.state.loading && this.state.selectedid !== null && 
                <MTAMTOScientometer 
                    selectedid={this.state.selectedid} 
                    pubs={this.state.pubs} 
                    date={this.state.date} 
                    author={this.state.author} 
                    options={this.state.options}
                />
            }
            {this.state.loading && <div className="mtmtspinner">
                <Spinner intent="primary" size="100" value={this.state.progress ? this.state.progress : undefined}/>
                <p>Publikációs adatok letöltése az MTMT-ből...</p>
                </div>
            }
        </div>
    }
}

export default withLocation(MTAMTOScoringApp);